import scoutimg01 from '../images/scoutPhotos/scout01.png';
import scoutimg02 from '../images/scoutPhotos/scout02.png';
import scoutimg03 from '../images/scoutPhotos/scout03.png';
import scoutimg04 from '../images/scoutPhotos/scout04.png';
import scoutimg05 from '../images/scoutPhotos/scout05.png';
import scoutimg06 from '../images/scoutPhotos/scout06.png';
import scoutimg07 from '../images/scoutPhotos/scout07.png';

export const introHighlights = {
    name: {
        text: "Jon Porter",
        hoverText: "It's not just a random name<br/> all over the website!"
    },
    background: {
        text: "3D Design/Product Design Engineering",
        hover: ""
    },
    UXUI: {
        text: "UX/UI Design",
        hover: ""
    },
    codecademy: {
        text: "Codecademy",
        hover: ""
    },
    cSharp: {
        text: "C#",
        hover: ""
    },
    python: {
        text: "Python",
        hover: ""
    },
    frontEnd: {
        text: "Front End Development and Web Design",
        hover: ""
    },
    designsPop: {
        text: "designs pop more",
        hover: ""
    },
    easterEggs: {
        text: "easter eggs",
        hover: ""
    },
    interests: {
        text: "movies, read books, draw, game, hang with friends",
        hover: ""
    },
    scout: {
        text: "cuddle my cat, Scout",
        hoverImage: [scoutimg01, scoutimg02, scoutimg03, scoutimg04, scoutimg05, scoutimg06, scoutimg07]
    }
}