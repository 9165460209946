import htmlIcon from '../images/skillIcons/htmlIcon.png';
import cssIcon from '../images/skillIcons/cssIcon.png';
import javascriptIcon from '../images/skillIcons/javascriptIcon.png';
import reactIcon from '../images/skillIcons/reactIcon.png';
import reduxIcon from '../images/skillIcons/reduxIcon.png';
import jsonIcon from '../images/skillIcons/jsonIcon.png';
import apiIcon from '../images/skillIcons/apiIcon.svg';
import githubIcon from '../images/skillIcons/githubIcon.png';
import designIcon from '../images/skillIcons/designIcon.svg';
import problemSolveIcon from '../images/skillIcons/problemSolvingIcon.svg';
import UXDesignIcon from '../images/skillIcons/UXdesignIcon.svg';
import cloudflareIcon from '../images/skillIcons/cloudflareIcon.png';
import responsiveIcon from '../images/skillIcons/responsiveIcon.svg';
import netlifyIcon from '../images/skillIcons/netlifyIcon.png';


export const skills = {
    html: {
        name: "HTML",
        icon: htmlIcon
    },
    css: {
        name: "CSS",
        icon: cssIcon
    },
    javascript: {
        name: "JavaScript",
        icon: javascriptIcon
    },
    react: {
        name: "React",
        icon: reactIcon
    },
    redux: {
        name: "Redux",
        icon: reduxIcon
    },
    json: {
        name: "JSON",
        icon: jsonIcon
    },
    api: {
        name: "API Integration",
        icon: apiIcon
    },
    github: {
        name: "Github",
        icon: githubIcon
    },
    netlify: {
        name: "Netlify",
        icon: netlifyIcon
    },
    cloudflare: {
        name: "Cloudflare",
        icon: cloudflareIcon
    },
    design: {
        name: "Design Background",
        icon: designIcon
    },
    UX: {
        name: "UX Design",
        icon: UXDesignIcon
    },
    responsiveDesign: {
        name: "Responsive Design",
        icon: responsiveIcon
    },

    problemSolve: {
        name: "Problem Solving",
        icon: problemSolveIcon
    }

}